<template>
  <v-container class="layout-container">
    <BaseHeader
      title="Datenschutz"
      :style-variant="9"
    >
      <template v-slot:design>
        <HeaderDesign />
      </template>

      <template v-slot:desc>
        <p>Alles rund um das Thema Datenschutz finden Sie hier zusammengefasst.</p>
      </template>
      <template v-slot:content>
        <v-breadcrumbs :items="breadcrumbItems" />
      </template>
    </BaseHeader>

    <v-card
      v-if="hasSeperateAvv"
      outlined
      class="my-2 pa-4 pa-md-8"
    >
      <span>MultiScale Vereinbarung zur Auftragsverarbeitung (AVV):
        <router-link
          class="text-decoration-none"
          to="/avv"
        >
          <span class="text-decoration-underline">hier einsehen und akzeptieren</span>
        </router-link>
      </span>
    </v-card>

    <Feature :feature-slug="featureNames.LICENSE_AGREEMENT">
      <v-card
        outlined
        class="my-2 pa-4 pa-md-8"
      >
        <span>MultiScale Nutzungs- und Lizenzbedingungen:
          <router-link
            class="text-decoration-none"
            to="/license-agreement"
          >
            <span class="text-decoration-underline">hier einsehen und akzeptieren</span>
          </router-link>
        </span>
      </v-card>
    </Feature>

    <v-card
      outlined
      class="my-2 pa-4 pa-md-8"
    >
      <span>MultiScale Datenschutzerklärung:
        <a
          class="text-decoration-none"
          target="_blank"
          rel="noopener noreferrer"
          href="https://bottimmo.com/de/datenschutzerklaerung/"
        > <span class="text-decoration-underline">hier einsehen </span>
          <v-icon
            class="ml-2"
            size="14"
            color="primary"
          >
            mdi-open-in-new
          </v-icon>
        </a>
      </span>
    </v-card>
    <v-card
      outlined
      class="my-2 pa-4 pa-md-8"
    >
      <span>Vorgeschlagene Textbausteine für Ihre Datenschutzerklärung:
        <a
          class="text-decoration-none"
          target="_blank"
          rel="noopener noreferrer"
          href="https://bottimmo.com/de/datenschutz-textbausteine/"
        > <span class="text-decoration-underline">hier einsehen</span>
          <v-icon
            class="ml-2"
            size="14"
            color="primary"
          >
            mdi-open-in-new
          </v-icon>
        </a>
      </span>
    </v-card>
  </v-container>
</template>

<script>
import BaseHeader from '@/components/BaseHeader.vue'
import HeaderDesign from '@/modules/privacy/HeaderDesign.vue'
import featureMixin from '@/mixins/feature'
import { Feature } from 'feature-config'

export default {
  components: { BaseHeader, HeaderDesign, Feature },
  mixins: [featureMixin],
  computed: {
    breadcrumbItems () {
      return [
        { text: 'Impressum & Rechtliches', to: { path: '/legal' }, disabled: false, exact: true },
        { text: 'Datenschutz' }
      ]
    },
    hasSeperateAvv () {
      const licenseAgreementFeature = this.getFeature(this.featureNames.LICENSE_AGREEMENT)
      return !(licenseAgreementFeature && licenseAgreementFeature.isActive && licenseAgreementFeature.config.hasAvv)
    }
  }
}
</script>
