<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 127.4 84.9"
    enable-background="new 0 0 127.4 84.9"
    xml:space="preserve"
  >

    <gradient-helper />

    <path
      :fill="fill ? 'url(#svg-gradient)' : 'none'"
      :stroke="stroke ? 'url(#svg-gradient)' : 'none'"
      :stroke-width="strokeWidth"
      d="M127.4,84.9c0,0-61.6-0.1-85-0.1S0,65.8,0,42.4S19-1.1,42.4,0C128.5,4.2,127.4,84.9,127.4,84.9z"
    />
  </svg>
</template>

<script>
import svg from '@/mixins/svg'
import GradientHelper from './GradientHelper.vue'
export default {
  components: { GradientHelper },
  mixins: [svg]
}
</script>
