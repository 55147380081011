<template>
  <div>
    <Oyster
      class="banner-design-elements"
      width="150px"
      style="right: 80px; top:-20px; opacity: 0.4"
      fill
    />

    <Leaf
      class="banner-design-elements"
      width="150px"
      style="right: 50px; top:30px; opacity: 0.9"
      stroke
      :stroke-width="1"
    />

    <SingleCircle
      class="banner-design-elements"
      width="30px"
      style="right: 40px; top:20px; opacity: 0.3"
      fill
    />
  </div>
</template>

<script>
import Leaf from '@/components/design-elements/leaf.vue'
import SingleCircle from '@/components/design-elements/singleCircle.vue'
import Oyster from '@/components/design-elements/oyster.vue'

export default {
  components: {
    Leaf,
    SingleCircle,
    Oyster
  }
}
</script>
